import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useTranslationList } from "hooks/use-translations-list";
import { phoneRegex, createWebsiteRule } from "services/validators";
import { idNameObject, useObjectValidations } from "validation/validaion";

export const useValidationRules = () => {
    const [__] = useTranslation();
    const { email, messengerRules, passwordRule, firstname, surname } =
        useObjectValidations();
    const {
        FIELD_SHOULD_BE_FILLED,
        CONTAINS_INVALID_CHARACTERS,
        DOES_NOT_MATCH_WITH_PASSWORD,
        INVALID_FORMAT,
        UP_TO_MAX_CHARACTERS,
    } = useTranslationList();

    const { messenger, messengerLogin } = messengerRules();

    const [rules] = useState(() => ({
        login: yup
            .string()
            .required(FIELD_SHOULD_BE_FILLED)
            .trim()
            .lowercase()
            .matches(/^[a-zA-Z0-9]+$/, CONTAINS_INVALID_CHARACTERS),
        password: passwordRule(),
        confirmPassword: yup
            .string()
            .required(FIELD_SHOULD_BE_FILLED)
            .test({
                message: DOES_NOT_MATCH_WITH_PASSWORD,
                test(value) {
                    return value === this.parent.password;
                },
            }),
        language: idNameObject().nullable().required(FIELD_SHOULD_BE_FILLED),
        firstname,
        surname,
        email: email(true),
        messenger,
        messengerLogin,
        country: idNameObject().nullable().required(FIELD_SHOULD_BE_FILLED),
        paymentSystem: yup
            .object({
                id: yup.number().nullable().required(FIELD_SHOULD_BE_FILLED),
                fields: yup.array().of(
                    yup
                        .object({
                            id: yup.string().required(FIELD_SHOULD_BE_FILLED),
                            value: yup.string(),
                        })
                        .required(),
                ),
            })
            .required(),
        rulesConfirmed: yup
            .boolean()
            .oneOf<boolean>([true], FIELD_SHOULD_BE_FILLED)
            .required(FIELD_SHOULD_BE_FILLED),

        phone: yup.string().matches(phoneRegex, INVALID_FORMAT),
        websiteCategory: yup.number().required(FIELD_SHOULD_BE_FILLED),
        website: createWebsiteRule(INVALID_FORMAT)
            .required(
                () =>
                    `${FIELD_SHOULD_BE_FILLED()}.\r\n${__(
                        "Если у вас нет сайта, укажите здесь http://example.com",
                    )}`,
            )
            .max(255, UP_TO_MAX_CHARACTERS)
            .trim(),
        howDidYouKnow: yup.number().required(FIELD_SHOULD_BE_FILLED),
        mailingConfirmed: yup.boolean(),
    }));

    return rules;
};
